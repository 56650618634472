@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);

/*Bootstrap v4*/
body, html {
	font-family: 'Open Sans', sans-serif;
}
a {
	color: #F54236;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
a:hover, a:active {
	color: #000;
}
.nopadding {
	padding-left: 0 !important;
	padding-right: 0 !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
}
img {
	max-width: 100%;
	height: auto;
}
.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
 }

/*Bootstrap Form*/
.form-control {
    border: 1px solid #fff;
    border-radius: 0px;
}
.form-control:focus {
    border-color: #000;
}
.btn {
    border-radius: 0px;
}
.btn-primary {
    color: #fff;
    background-color: #F54236;
    border-color: #F54236;
}
.btn-primary.focus, .btn-primary:focus, .btn-primary:hover {
    color: #fff;
    background-color: #C4342B;
    border-color: #C4342B;
}
.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open>.btn-primary.dropdown-toggle.focus, .open>.btn-primary.dropdown-toggle:focus, .open>.btn-primary.dropdown-toggle:hover {
    color: #fff;
    background-color: #C4342B;
    border-color: #C4342B;
}

/*Structure*/
.bgslider {
	position: relative;
	width: 100%;
	height: auto;
}
.website {
	position: relative;
	z-index: 2;
}

/*General*/
.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
	margin-bottom: 1em;
}
.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
	margin-bottom: 1em;
}
.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1em;
}

/*Content*/
.color_title {
	color: #C63224;
}

/*Slider*/
#slider .item img {
	display: block;
	width: 100%;
	height: auto;
}

/*Navigation*/
.bg-inverse {
    color: #eceeef;
    background-color: #3D3B3C;
	-webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.2);
	box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}
.navbar {
    border-radius: 0px;
}
.navbar-inner .nav {
	float: none;
	display: inline-block;
	text-transform: uppercase;
}
.dropdown-menu {
    padding: 1em 0;
    margin: 2px 0 0;
    background-color: #2c2a2b;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0px;
}
ul.dropdown-menu li {
    padding: .2em 1.2em;
}
.btn-nav {
	display: none;
}

/*Data box*/
.only-data-box {
	position: absolute;
	width: 100%;
	top: 100px;
}
.data-box {
	margin-top: 2em;
}
a.logo {
	display: block;
	background: transparent;
	text-align: center;
}
.first-tel {
	background: #F54236;
	color: #fff;
}
.first-tel-title {
	padding: .6em 0 0 0;
}
a.cell_number {
	display: block;
	font-size: 2em;
	color: #fff;
}
a.cell_number:hover {
	text-decoration: none;
}
.prefix {
	font-size: 0.6em;
	margin: auto .4em;
}
.second-tel {
	padding-top: 1em;
	background: transparent;
	color: #fff;
}
.second-tel-title {
	margin: 0 auto;
	padding: 0 .4em;
	border: 2px solid #fff;
}
a.green_number {
	display: block;
	padding-bottom: .2em;
	text-align: center;
	font-size: 3em;
	font-weight: bold;
	width: 100%;
	height: 140px;
	background: url(../../img/numero_verde.png) no-repeat;
	background-position: 50% 0;
	color: #fff;
}
a.green_number:hover {
	text-decoration: none;
}
.mobile_geen_number {
	display: none;
}
.icon_green_tel {
	margin-right: .4em;
}
.data-box-buttons {
	text-align: center;
	display: none;
}
.data_box_title {
	display: block;
	font-size: 1em;
	padding: .4em 1em;
	font-style: italic;
	text-transform: uppercase;
	background: rgba(0,0,0,.6);
	color: #fff;
	-webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.2);
	box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}
a.data_box_link {
	display: block;
	font-size: 1.5em;
	text-transform: uppercase;
	padding: .6em 1.2em;
	background: rgba(245,66,54,0.7);
	color: #fff;
	-webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.2);
	box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}
a.data_box_link:hover {
	text-decoration: none;
	background: #C4342B;
}
.icon_data_box {
	margin-right: .4em;
}

/*Services*/
.box-services {
	margin-top: 0;
	padding-top: 1.6em;
	padding-bottom: 1.6em;
	font-style: italic;
	background: #3D3B3C;
	color: #fff;
}
.service {
	display: inline-block;
	margin-left: 1em;
}
.icon_service {
	margin-right: .4em;
	color: #FFCD3A;
}

/*Partners*/
.partners {
	padding-top: 2em;
	padding-bottom: 2em;
	background: #fff;
}
#partners .item img {
	display: block;
	width: 100%;
	max-height: 80px;
}
#partners .item {
	margin-right: 2em;
}

/*Content*/
.content {
	padding-bottom: 2em;
	background: #fff;
}

/*Links Pages*/
.links-pages {
	padding: 2em;
	background: #F54236;
	color: #fff;
	-webkit-border-radius: 3px;
	border-radius: 3px;
}
.links-pages a {
	display: block;
	color: #fff;
}

/*Footer Box*/
.footer-box {
	padding-top: 3em;
	padding-bottom: 3em;
	background: #3D3B3C;
	color: #fff;
}

/*Footer Contact*/
.footer-contact {
	background: #F54236;
	color: #fff;
}
.foo-pad {
	display: block;
	padding-top: 2em;
}
.fc_title {
	padding-top: 2.6em;
}
.fc-greentel {
	padding-top: 3em;
	padding-bottom: 1em;
	background: #fff;
}

/*Footer*/
.footer-text {
	padding-top: 2em;
}

/*Parsley*/
ul.parsley-errors-list {
	list-style: none;
	margin: 0;
	padding: 0;
}
ul.parsley-errors-list li {
	padding: 1em;
	background: #FC6E51;
	color: #fff;
}

/* Social */
.social_net {
	display: block;
	padding: 2rem 1rem;
	font-size: 1.4rem;
	text-align: center;
	text-decoration: none;
	color: #fff;
}
a.social_net:hover {
	text-decoration: none;
	color: #fff;
}
.social_facebook {
	background-color: #3b5998;
}
.social_youtube {
	background-color: #cd201f;
}

/*RESPONSIVE*/

@media (max-width: 74.9em) {
	
}

@media (max-width: 61.9em) {
	.navbar-inner .nav {
		font-size: 0.9em;
	}
	.partners {
		padding-bottom: 0;
	}
	h1.color_title {
		font-size: 1.8em;
	}
	.fc-cell a {
		font-size: 1.4em;
	}
	a.green_number_footer {
		background-size: 80%;
	}
	.fc-greentel {
		background-color: #fff;
	}

}

@media (max-width: 47.9em) {
	a.cell_number {
		font-size: .9em;
	}
	a.green_number_upper {
		display: none;
	}
	.mobile_geen_number {
		display: block;
		float: left;
		width: 134px;
		height: 60px;
		color: #fff;
		background: url(../../img/numero_verde.png) no-repeat;
		background-size: cover;
	}
	a.mobile_geen_number:hover {
		color: #fff;
	}
	.mobile_geen_number span {
		display: block;
		text-transform: uppercase;
		font-size: .5em;
	}
	.data-box-contacts {
    	height: 30vh;
	}
	#slider .item img {
		min-height: auto;
	}
	.btn-nav {
		display: inline-block;
		padding: .2em .6em;
		float: right;
		text-transform: uppercase;
		border: 1px solid #fff;
		-webkit-border-radius: 3px;
		border-radius: 3px;
		cursor: pointer;
	}
	.btn-nav::before {
		margin-right: .4em;
		font-size: 1.4em;
		vertical-align: bottom;
	}
	ul.nav {
		position: absolute;
		z-index: 999;
		top: 4.6em;
		left: 0;
		width: 100%;
		margin: 0;
		padding: 0;
		text-align: center;
		background: #222;
	}
	.breadcrumb>li,
	.navbar-brand,
	.navbar-divider,
	.navbar-nav .nav-item {
		float: none;
		border-bottom: 1px solid #000;
	}
	.navbar-nav .nav-link {
		padding-top: 1em;
		padding-bottom: 1em;
	}
	.navbar-inner ul.nav {
		display: none;
	}
	.navbar-inner ul.nav.opened {
		display: block;
	}
	.data-box {
    	margin-top: 0px;
	}
	a.logo img {
		max-width: 100%;
		max-height: 60px;
	}
	.first-tel {
		margin-top: 150px;
	}
	.first-tel-title.h3 {
		font-size: 1em;
	}
	.data-box-buttons {
		padding: 0;
	}
	a.data_box_title {
		font-size: 0.8em;
		-webkit-border-radius: 0px;
		border-radius: 0px;
	}
	a.data_box_link {
		margin: 0;
		width: 100%;
		font-size: 1.3em;
		float: left;
		padding: 1em 1.2em;
		-webkit-box-shadow: none;
		box-shadow: none;
		-webkit-border-radius: 0px;
		border-radius: 0px;
		background: #F8402D;
	}
	.box-services {
    	margin-top: 0;
	}
	.fc_title {
		padding-top: .6em;
	}
	a.cell_number {
		padding: 0;
	}
	.fc-cell a {
		font-size: 1.8em;
	}

	/*General*/
	.alignleft {
		display: block;
		float: none;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 1em;
	}
	.alignright {
		display: inline;
		float: none;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 1em;
	}
	.aligncenter {
		clear: both;
		display: block;
		float: none;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 1em;
	}
}

@media (max-width: 33.9em) {
	.footer-box h2 {
		font-size: 1.2em;
		font-weight: bold;
	}
	.footer-box ul {
		padding: 0;
		list-style: none;
		text-align: center;
	}
	.footer {
		text-align: center;
	}
	.content h3 {
		font-size: 1.2em;
	}
	h1.color_title {
		font-size: 1.2em;
	}
	.data-box-contacts {
	    height: 31vh;
	}
	a.data_box_link {
		font-size: 1.1em;
	}
	.links-pages a {
		text-align: center;
	}
}
